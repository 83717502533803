<template>
  <div class="driedfood">
    <div class="search">
      <div class="title">
        <span>热门关键字</span>
        <img src="../../static/img/hot.png" alt="" />
      </div>
      <div class="searchlist">
        <div
          :class="indextel == index ? 'redsearchbox' : 'searchbox'"
          v-for="(item, index) in searchlistlist"
          :key="index"
          @click="setkeywordsA(item, index)"
        >
          {{ item.name }}
        </div>
      </div>
    </div>
    <div class="list">
      <div class="box" v-for="(item, index) in list" :key="index">
        <div class="msg">
          <div class="image">
            <el-image
              class="elimage"
              :src="item.DryGoodsImage"
              @click="gotoPostdetails(item)"
              fit="cover"
            ></el-image>
          </div>
          <div class="right">
            <div class="title" @click="gotoPostdetails(item)">
              {{ item.DryGoodsTitle }}
            </div>
            <div class="conter" @click="gotoPostdetails(item)">
              {{ item.Content | string }}
            </div>
            <div class="footer">
              <div class="left" @click="gotoOtherspersonal(item)">
                <img :src="item.Avatar" alt="" />
                <span>{{ item.Name }}</span>
              </div>
              <div class="messgen">
                <span
                  ><i
                    class="el-icon-chat-dot-round"
                    style="margin-right: 5px"
                  ></i
                  >{{ item.Comment }}</span
                >
                <span>{{ item.time }}</span>
              </div>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
      </div>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        pageIndex: 1,
        pageSize: 10,
        keywordsA: "",
        MemberCode: "",
      },
      list: [],
      indextel: 0,
      searchlistlist: [
        {
          name: "全部",
        },
        {
          name: "收集",
        },
        {
          name: "茶道",
        },
        {
          name: "服饰",
        },
        {
          name: "琴棋书画",
        },
        {
          name: "民俗",
        },
        {
          name: "其他",
        },
      ],
      total: 0,
    };
  },
  created() {
    this.getDryGoodsList();
  },
  methods: {
    // 分类
    setkeywordsA(item, index) {
      this.form.keywordsA = item.name;
      this.indextel = index;
      this.getDryGoodsList();
    },
    // 去用户首页
    gotoOtherspersonal(item) {
      this.$router.push(`Otherspersonal?id=${item.MemberCode}`);
    },
    // 下一页
    handleCurrentChange(val) {
      this.form.pageIndex = val;
      this.getDryGoodsList();
    },
    gotoPostdetails(item) {
      this.$router.push(`Postdetails?FindCode=${item.FindCode}`);
    },
    getDryGoodsList() {
      this.$api.common.DryGoodsList(this.form).then((res) => {
        console.log(res);
        this.list = res.data.list;
        this.total = res.data.total;
      });
    },
  },
  filters: {
    string(val) {
      if (val.length > 80) {
        let str = val.substr(0, 80);
        str = str + "...";
        return str;
      } else {
        return val;
      }
    },
  },
};
</script>

<style lang='less' scoped>
@import url("./index.less");
/deep/.el-divider--horizontal {
  background-color: #f8f8f8;
}
</style>
